import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthGuard} from 'vertue-common';


const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate: [
      AuthGuard,
    ],
  },
  // {
  //   path: 'message/:id',
  //   loadChildren: () => import('./view-message/view-message.module').then( m => m.ViewMessagePageModule),
  //   canActivate: [
  //     AuthGuard,
  //   ],
  // },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'signedout',
    loadChildren: () => import('./signedout/signedout.module').then( m => m.SignedoutPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
