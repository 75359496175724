import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {Router, RouteReuseStrategy} from '@angular/router';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthInterceptor} from '../app/services/auth-inteceptor.service';
import { AuthService, VertueCommonModule, VertueServiceConfig } from 'vertue-common';
import {environment} from '../environments/environment';
import {Capacitor} from '@capacitor/core';
import {Plugins} from 'protractor/built/plugins';
import Auth0Cordova from '@auth0/cordova';
import Auth0 from 'auth0-js';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
      BrowserModule,
    VertueCommonModule.forRoot(environment as VertueServiceConfig),
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    AndroidPermissions,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide : HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi   : true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(router: Router, authService: AuthService) {
    router.events.forEach((event) => {
      console.log('URL' + (event as any).url);
    });

    authService.nativeInit(Auth0.WebAuth, Auth0Cordova);
  }

}


